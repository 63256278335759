<template>
  <!-- header -->
  <nav class="navbar navbar-expand-lg navbar-light bg-white w-100" style="z-index: 999">
    <div class="container-fluid px-5">
      <a class="navbar-brand" href="#"
        ><img
          src="@/assets/images/logo.png"
          width="80"
          height="80"
          class="d-inline-block align-top"
          alt=""
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo02"
        aria-controls="navbarTogglerDemo02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav ml-auto mt-2 mt-lg-0 mr-lg-4">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about-us" class="nav-link"
              >About Mimo</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/services" class="nav-link">Services</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/blogs" class="nav-link">Blogs</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/promotions" class="nav-link"
              >Promotions</router-link
            >
          </li>
          <li class="nav-item">
            <router-link to="/contacts" class="nav-link">Contact</router-link>
          </li>

          <li class="nav-item dropdown" v-if="isLoggedIn">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              My Account
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link
                to="/customers/my-profile"
                class="dropdown-item"
                v-if="role == 'customers'"
                >My Profile</router-link
              >
              <router-link
                to="/admin/dashboard"
                class="dropdown-item"
                v-if="role == 'admin' || role == 'finance'"
                >Back To Dashboard</router-link
              >
              <router-link
                to="/warehouse/dashboard"
                class="dropdown-item"
                v-if="role == 'warehouse'"
                >Back To Dashboard</router-link
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click.prevent="logout">Logout</a>
            </div>
          </li>

          <li class="nav-item" v-if="!isLoggedIn">
            <router-link to="/login" class="btn btn-primary my-2 my-sm-0 px-5">
              Login</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.dropdown-item.active,
.dropdown-item:active {
  color: black;
  text-decoration: none;
  border-radius: 25px;
  background-color: transparent;
}
</style>
<script>
import Vue from "vue";

export default {
  data() {
    return {
      role: localStorage.getItem("role"),
    };
  },
  mounted() {
    this.role = localStorage.getItem("role");
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    logout: function () {
      Vue.swal({
        title: "Are you sure?",
        text: "Leaving this page, you will return to the main page of the website",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#eb4d4b",
        confirmButtonText: "Yes, Logout!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("logout").then(() => {
            Vue.swal("Logout Successfully!", "See you again!", "success").then(
              () => {
                this.$router.push("/login");
              }
            );
          });
        }
      });
    },
  },
};
</script>
