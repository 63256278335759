<template>
  <div>
    <div v-if="loadPage">
      <navbar></navbar>
      <div class="container">
        <div class="main-body mt-5">
          <div class="row gutters-sm">
            <div class="col-md-4 mb-3">
              <div class="card shadow p-3">
                <div class="card-body">
                  <div
                    class="d-flex flex-column align-items-center text-center"
                  >
                    <img
                      src="https://bootdey.com/img/Content/avatar/avatar7.png"
                      alt="Admin"
                      class="rounded-circle"
                      width="150"
                    />
                    <div class="mt-3">
                      <h4>{{ userLogin.name }}</h4>
                      <p class="text-secondary mb-1">{{ userLogin.email }}</p>
                      <p class="text-muted font-size-sm">
                        {{ userLogin.address }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card shadow p-5 mt-3">
                <div
                  class="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <router-link to="/customers/my-profile" class="nav-link"
                    >My Profile
                  </router-link>

                  <router-link to="/customers/my-packages" class="nav-link"
                    >My Packages
                  </router-link>

                  <router-link to="/customers/my-notification" class="nav-link"
                    >My Notification
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card shadow p-4">
                <div class="card-body">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-customer></footer-customer>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
// import ModalEditProfile from "../../../components/customers/customer-area/ModalEditProfile.vue";
import navbar from "../components/customers/NavbarCustomer.vue";
import footerCustomer from "../components/customers/FooterCustomer.vue";
export default {
  name: "Customer",
  components: {
    navbar,
    footerCustomer,
  },

  data() {
    return {
      loadNotification: "",
      loadPage: true,
    };
  },

  computed: {
    userLogin: function () {
      return this.$store.getters.getProfileCustomers;
    },
  },
  created() {
    this.cekLogin();
  },
  mounted() {
    this.cekLogin();
    this.getProfileUser();
    console.log(this.userLogin);
  },
  methods: {
    cekLogin() {
      if (localStorage.getItem("role") !== "customers") {
        this.loadPage = false;
        Vue.swal(
          "Unauthorized!",
          "You do not have rights to access this page!",
          "error"
        ).then(() => {
          if (localStorage.getItem("role") == "warehouse") {
            this.$router.push("/warehouse/dashboard");
          } else if (localStorage.getItem("role") == "admin" || localStorage.getItem("role") == "finance") {
            this.$router.push("/admin/dashboard");
          }
        });
      }
    },
    getProfileUser() {
      this.$store.dispatch("getProfileCustomers");
    },

    getNotification() {
      this.scrollToTop();
    },

    getPackages() {
      this.scrollToTop();
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
